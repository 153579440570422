import { html, LitEntity } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';
import './bn-icon';

const observer = new IntersectionObserver(function(entries) {
  if (entries[0].isIntersecting === true) {
    entries[0].target._loadMore();
  }
}, { threshold: [0], rootMargin: '0px 0px 150% 0px' });

class BnLoadMoreButton extends LitEntity {
  constructor() {
    super();
    this.isFetching = false;
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.manualOnly) {
      observer.observe(this);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (!this.manualOnly) {
      observer.disconnect();
    }
  }

  static get properties() {
    return {
      manualOnly: { type: Boolean, attribute: 'manual-only' },
      loadAll: { type: Boolean, attribute: 'load-all' },
      isFetching: { type: Boolean, attribute: false },
      nextHref: { type: String, attribute: false },
    };
  }

  static get styles() {
    return sharedStyles;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.nextHref = this.getHref('next');
    this.loadAll && this._loadMore();
  }

  render() {
    if (!this.nextHref) {
      return html``;
    }
    return html`
      <div class="list-actions">
        <button class="button button--outlined" @click="${this._loadMore}" ?disabled="${this.anyLoading()}">
          ${this.anyLoading() ? html`Loading <bn-icon name="fas fa-spinner fa-pulse"></bn-icon>` : 'More...'}
        </button>
      </div>
    `;
  }

  updated() {
    if (!this.manualOnly) {
      // Manually trigger intersection check in case loaded entries are already within the viewport + margin bounding box
      observer.unobserve(this);
      observer.observe(this);
    }
  }

  anyLoading() {
    return this.loading || this.isFetching || this.refreshing;
  }

  async _loadMore() {
    if (!this.nextHref || this.anyLoading()) {
      return;
    }

    this.isFetching = true;
    const siren = await this.fetchSiren(this.nextHref);
    this.isFetching = false;
    if (!siren) {
      return;
    }

    this.dispatchEvent(new CustomEvent('moreloaded', { detail: { entity: siren } }));
    this.nextHref = this.getHref('next', siren);
    this.loadAll && this._loadMore();
  }
}

window.customElements.define('bn-load-more-button', BnLoadMoreButton);
