import { useEffect } from 'react';
import { useStore } from '../BinSentry-ui-utility.js';

/**
 * Initializes localizejs
 */
export function useLocalize(config) {
  const { userFeatures } = useStore();
  const enableLocalization = userFeatures?.enableLocalization === true;

  useEffect(() => {
    if (enableLocalization) {
      if (config.localize_api_key) {
        window.Localize?.initialize({
          key: config.localize_api_key,
          rememberLanguage: true,
        });
      }
      if (config.weglot_api_key) {
        window.Weglot.initialize({
          api_key: config.weglot_api_key,
        });
      }
    }
  }, [enableLocalization, config]);
}
