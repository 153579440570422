import { SirenActionMixin } from '../utility/siren-action-mixin';
import { LitEntity, css, html } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';
import '~/legacy-lit/js/bins/parts/bin-list';

class BinSearchResults extends SirenActionMixin(LitEntity) {
  static get properties() {
    return {
      query: { type: String, arrtibute: false },
      results: { type: Array, attribute: false },
      referencePoint: { type: Object, attribute: 'reference-point' },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: block;
        }
        p {
          background-color: var(--white-base);
          margin-left: 0.5rem;
          margin-top: 0;
        }
      `,
    ];
  }

  render() {
    if (!this.href) {
      return html``;
    }
    if (this.loading) {
      return html`<bn-loading></bn-loading>`;
    }
    if (!this.query && (!this.results || !this.results.length)) {
      return html`<p>Enter your search text above to get results.</p>`;
    }
    if (!this.results || !this.results.length) {
      return html`<p>No Results</p>`;
    }
    return html`
      <bin-list
        .href="${this.href}"
        reference-point="${JSON.stringify(this.referencePoint)}"
        @select="${() => this.dispatchEvent(new CustomEvent('select'))}"
      ></bin-list>
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    if (this.entity) {
      const action = this.entity.getActionByName('search-bins');
      const fields = this.getActionFields(action);
      this.query = fields.get('query');
    }
    this.results = (this.entity && this.entity.entities) || [];
  }
}

window.customElements.define('bin-search-results', BinSearchResults);
