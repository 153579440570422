import _ from 'lodash';
import { createTheme, createGenerateClassName } from '~/lib/bn-material-ui';
import { colors } from './colours.js';
import { getMuiTypographyThemeOverrides } from './mui-typography.js';

const palette = {
  ...colors,
  primary: {
    main: colors.core,
  },
  // currently no secondary colour, point to primary
  secondary: {
    main: colors.core,
  },
  info: {
    light: colors.blue[50],
    main: colors.blue[60],
    dark: colors.blue[60],
  },
  error: {
    light: colors.red[10],
    main: colors.red[30],
    dark: colors.red[30],
  },
  warning: {
    light: colors.yellow[20],
    main: colors.yellow[20],
    dark: colors.yellow[30],
  },
  background: {
    default: colors.gray[10],
  },
  fillLevel: {
    low: colors.red[20],
    lowDark: colors.red[20],
    medium: colors.orange[20],
    mediumDark: colors.orange[20],
    high: colors.green[15],
    highDark: colors.green[20],
  },
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const theme = createTheme({
  palette: _.omit(palette, 'grey'),
  breakpoints: {
    values: breakpoints,
  },
  typography: getMuiTypographyThemeOverrides(breakpoints),
  components: {
    MuiAlert: {
      defaultProps: {
        severity: 'info',
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          alignItems: 'center',
          padding: 8,
          margin: '10px 0px',
          borderRadius: 2,
        },

        filledInfo: {
          backgroundColor: palette.blue[10],
          color: palette.blue[50],
          '& .MuiAlertTitle-root': {
            color: palette.blue[50],
          },
        },

        filledWarning: {
          backgroundColor: palette.yellow[10],
          color: palette.yellow[30],
          '& .MuiAlertTitle-root': {
            color: palette.yellow[30],
          },
        },

        filledError: {
          backgroundColor: palette.red[10],
          color: palette.red[30],
          '& .MuiAlertTitle-root': {
            color: palette.red[30],
          },
        },

        filledSuccess: {
          backgroundColor: palette.green[10],
          color: palette.green[30],
          '& .MuiAlertTitle-root': {
            color: palette.green[30],
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-popupIndicator:not(.Mui-disabled):hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: palette.blue[20],
          color: palette.blue[50],
          fontWeight: 900,
          border: `1px solid ${palette.blue[50]}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          // no design has shadows
          boxShadow: 'none !important',
          fontWeight: 400,
        },

        // FIGMA = Buttons | inline
        sizeSmall: {
          minHeight: 29,
          padding: '5.5px, 12px',
        },

        // FIGMA = Buttons | all non-inlines
        sizeMedium: {
          minHeight: 34,
          padding: '8px, 12px',
        },

        // FIGMA = Buttons | Text
        text: {
          backgroundColor: 'transparent',
          color: palette.blue[50],
          border: '1px solid transparent',

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[10],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[20],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.blue[50],
          },
          '&.Mui-disabled': {
            borderWidth: 0,
            color: palette.gray[60],
            opacity: 0.6,
          },
        },

        // FIGMA = Buttons | Primary
        containedPrimary: {
          backgroundColor: palette.blue[10],
          border: `1px solid ${palette.blue[50]}`,
          color: palette.blue[60],

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[20],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[30],
          },
          '&:not(.Mui-disabled):focus': {
            outlineOffset: 3,
            outline: `1px solid ${palette.blue[50]}`,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.gray[40],
            borderColor: 'transparent',
            color: palette.gray[70],
            opacity: 0.6,
          },
        },

        // FIGMA = Buttons | Secondary
        outlinedPrimary: {
          backgroundColor: 'transparent',
          border: `1px solid ${palette.blue[50]}`,
          color: palette.blue[50],

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[10],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[10],
          },
          '&:not(.Mui-disabled):focus': {
            outlineOffset: 3,
            outline: `1px solid ${palette.blue[50]}`,
          },
          '&.Mui-disabled': {
            borderColor: palette.gray[60],
            color: palette.gray[60],
            opacity: 0.6,
          },
        },

        // FIGMA = Buttons | Destructive (Filled)
        containedError: {
          backgroundColor: palette.red[20],
          border: '1px solid transparent',
          color: palette.white,

          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.red[30],
          },
          '&:not(.Mui-disabled):focus': {
            outlineOffset: 3,
            outline: `1px solid ${palette.red[20]}`,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            borderColor: palette.gray[60],
            color: palette.gray[60],
          },
        },

        // FIGMA = Buttons | Destructive (Outline)
        outlinedError: {
          backgroundColor: 'transparent',
          border: `1px solid ${palette.red[20]}`,
          color: palette.red[20],

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.red[10],
            border: `1px solid ${palette.red[30]} !important`,
            color: palette.red[30],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.red[10],
            borderColor: palette.red[30],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.red[20],
            outlineOffset: 3,
            outline: `1px solid ${palette.red[20]}`,
          },
          '&.Mui-disabled': {
            borderColor: palette.gray[60],
            color: palette.gray[60],
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          // selected
          '& .MuiButton-contained': {
            outline: 'none !important',
            backgroundColor: palette.blue[50],
            color: palette.white,
            '&:not(.Mui-disabled):hover': {
              backgroundColor: palette.blue[50],
              color: palette.white,
            },
          },
          // unselected
          '& .MuiButton-outlined': {
            outline: 'none !important',
            backgroundColor: palette.white,
            color: palette.blue[50],
            '&:not(.Mui-disabled):hover': {
              backgroundColor: palette.blue[10],
              color: palette.blue[50],
            },
            '&:not(.Mui-disabled):focus': {
              backgroundColor: palette.blue[20],
              color: palette.blue[50],
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: palette.gray[80],
          backgroundColor: palette.gray[30],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: palette.blue[50],
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.orientation === 'vertical' && ownerState.variant === 'middle' && {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }),
        }),
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        // FIGMA = Buttons | Icon Buttons
        colorPrimary: {
          color: palette.blue[50],
          border: '1px solid transparent',

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[10],
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[20],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.blue[50],
          },
          '&.Mui-disabled': {
            color: palette.gray[60],
            borderWidth: 0,
            opacity: 0.5,
          },
        },

        // NOT IN FIGMA | Red Icon Buttons (delete)
        colorError: {
          color: palette.red[20],
          border: '1px solid transparent',

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.red[10],
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.red[10],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.red[20],
          },
          '&.Mui-disabled': {
            color: palette.gray[60],
            borderWidth: 0,
            opacity: 0.5,
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: palette.secondary.main,
          },
        },

        underline: {
          '&:before': {
            borderBottomColor: palette.secondary.main,
          },
          '&:after': {
            borderBottomColor: palette.secondary.main,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: palette.secondary.main,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        standard: {
          transform: 'translate(0, 1.5px) scale(0.75)',
          transformOrigin: 'top left',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: '0, 5px !important',
        },

        // FIGMA = Links | Text Only
        underlineHover: {
          color: palette.blue[50],
          fontWeight: 400,
          border: '1px solid transparent',
          '& .MuiTypography-root': {
            color: palette.blue[50],
          },

          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[10],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[20],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.blue[50],
            textDecoration: 'underline',
          },
          '&.Mui-disabled': {
            borderWidth: 0,
            color: palette.gray[60],
            opacity: 0.6,
          },
        },

        // FIGMA = Links | Underlined
        underlineAlways: {
          color: palette.blue[50],
          fontWeight: 400,
          border: '1px solid transparent',
          textAlign: 'center',
          '& .MuiTypography-root': {
            color: palette.blue[50],
          },

          '&::after': {
            display: 'block',
            color: 'transparent',
            overflow: 'hidden',
            height: 1,
            visibility: 'hidden',
          },
          '&:not(.Mui-disabled):hover': {
            backgroundColor: palette.blue[10],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):active': {
            backgroundColor: palette.blue[20],
            color: palette.blue[60],
          },
          '&:not(.Mui-disabled):focus': {
            borderColor: palette.blue[50],
          },
          '&.Mui-disabled': {
            borderWidth: 0,
            color: palette.gray[60],
            opacity: 0.6,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          color: palette.gray[80],
          '& .MuiSvgIcon-root': {
            color: palette.gray[60],
          },
          '& li': {
            gap: '0.5rem',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },

        elevation: {
          backgroundColor: 'none',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: palette.blue[50],
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: palette.black,
          '& .MuiSelect-iconStandard': {
            color: palette.primary.main,
          },
        },

        standard: {
          borderBottom: `1px solid ${palette.primary.main}`,
          '& .MuiFormLabel-root': {
            color: palette.primary.main,
          },

          '&:before': {
            borderBottom: `1px solid ${palette.primary.main}`,
          },
          '&:not(.Mui-disabled):hover::before': {
            borderBottom: `2px solid ${palette.primary.main}`,
          },
          '&:not(.Mui-disabled):active::before': {
            borderBottom: `2px solid ${palette.primary.main}`,
          },
          '&:not(.Mui-disabled):focus::before': {
            borderBottom: `2px solid ${palette.primary.main}`,
          },
          '&.Mui-disabled': {
            color: palette.gray[70],
            borderBottom: `1px solid ${palette.gray[60]}`,
            opacity: 0.6,
            '& .MuiFormLabel-root': {
              color: palette.gray[60],
            },
            '& .MuiSelect-iconStandard': {
              color: palette.gray[60],
            },
          },
          '&:not(.Mui-disabled):error': {
            borderBottom: `2px solid ${palette.red[20]}`,
          },
        },

        outlined: {
          border: `1px solid ${palette.gray[50]}`,
          '& .MuiInputLabel-outlined': {
            color: palette.gray[70],
          },

          '&:before': {
            border: `1px solid ${palette.gray[50]}`,
          },
          '&:not(.Mui-disabled):hover::before': {
            border: `1px solid ${palette.gray[60]}`,
          },
          '&:not(.Mui-disabled):active::before': {
            borderBottom: `2px solid ${palette.gray[60]}`,
          },
          '&:not(.Mui-disabled):focus::before': {
            borderBottom: `1px solid ${palette.gray[50]}`,
          },
          '&.Mui-disabled': {
            color: palette.gray[80],
            border: `1px solid ${palette.gray[70]}`,
            backgroundColor: palette.gray[20],
            opacity: 0.6,
            '& .MuiSelect-iconOutlined': {
              color: palette.gray[60],
            },
          },
          '&:not(.Mui-disabled):error': {
            border: `1px solid ${palette.red[20]}`,
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '2.4em',
          padding: '0 .5em',
          transition: 'background-color 0.2s',
          minWidth: '10rem',
          color: palette.blue[50],
          fontWeight: 700,

          '&:not(.Mui-disabled):not(.Mui-selected):hover': {
            backgroundColor: palette.blue[10],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '2.4em',
        },

        indicator: {
          backgroundColor: palette.blue[50],
          height: '3px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: palette.secondary.main,
          },
          '& .MuiInput-underline': {
            '&:before': {
              borderColor: palette.secondary.main,
            },
            '&:after': {
              borderColor: palette.secondary.main,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: palette.secondary.main,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.white,
          boxShadow: `0.1rem 0.1rem 0.2rem 0 ${colors.gray[30]}`,
          border: `1px solid ${colors.gray[40]}`,
          color: palette.black,
        },
      },
    },
  },
});

let ruleCount = 0;
const muiGenerateClassName = createGenerateClassName();
export const generateClassName = (rule, styleSheet) => {
  // If this is a global static MUI style, use the mui lib fn
  const name = styleSheet.options.name;
  if (name && name.indexOf('Mui') === 0) {
    return muiGenerateClassName(rule, styleSheet);
  }

  return `${rule.key}-${ruleCount++}`;
};
