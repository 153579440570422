import _ from 'lodash';
import { css, unsafeCSS } from '~/lib/bn-lit-element';
import { alpha } from '~/lib/bn-material-ui';

const gray = {
  0:  '#FFFFFF',
  10: '#FAFAFA',
  20: '#F5F5F5',
  30: '#E5E5E6',
  40: '#D7D7D9',
  50: '#AEAEAF',
  60: '#717477',
  70: '#4B4F54',
  80: '#303236',
  90: '#17181A',
};

const accentBlue = {
  10: '#DBEBF3',
  20: '#ACD0E3',
  30: '#7CB5D2',
  40: '#4D9BC2',
  50: '#306D8C',
  60: '#1D5A73',
};

const binsentryColours = {
  teal: {
    10: '#C9E5E4',
    20: '#3D9F9E',
    30: '#1F5150',
  },

  green: {
    10: '#C6D5B6',
    15: '#719749',
    20: '#587639',
    30: '#384B24',
  },

  yellow: {
    10: '#FFF7D3',
    20: '#F1BC2A',
    30: '#845C00',
  },

  red: {
    10: '#F6DAD8',
    20: '#BB342A',
    30: '#75211A',
  },

  orange: {
    10: '#F7BB88',
    20: '#F28D39',
    30: '#874209',
  },
};

const monochromaticColours = {
  lavender: {
    10: '#D7CEE9',
    20: '#B8A6DB',
    30: '#967CC9',
    40: '#7849B4',
    50: '#5A3789',
    60: '#3E2861',
  },
  forest: {
    10: '#D0E1DD',
    20: '#B1CDC6',
    30: '#73A598',
    40: '#5A8C7E',
    50: '#2F6D5D',
    60: '#224E42',
  },
  indigo: {
    10: '#C9D1E5',
    20: '#9EABD1',
    30: '#7286BD',
    40: '#4D64A2',
    50: '#3C4E7F',
    60: '#303E66',
  },
};

export const colors = {
  // grayscale colours
  black: gray[90],
  white: gray[0],
  gray,
  grey: gray,

  // brand colours
  core: '#004A7E',
  hover: alpha(accentBlue[40], 0.1),
  blue: accentBlue,

  // binsentry colours
  teal: binsentryColours.teal,
  green: binsentryColours.green,
  yellow: binsentryColours.yellow,
  red: binsentryColours.red,
  orange: binsentryColours.orange,

  // monochromatic colours
  lavender: monochromaticColours.lavender,
  forest: monochromaticColours.forest,
  indigo: monochromaticColours.indigo,
};

export const LitColours = css`
  :root,
  * {
    ${unsafeCSS(`
      --fill-level-low: ${colors.red[20]};
      --fill-level-medium: ${colors.orange[20]};
      --fill-level-high: ${colors.green[15]};
      --black-base: ${colors.black};
      --white-base: ${colors.white};
      --primary: ${colors.core};
      --hover-color: ${colors.hover};
      --background-color: #E4E7E7;
    `)}
    ${(
    () => {
      const shadedColors = _.omit(colors, ['black', 'white', 'core', 'hover', 'grey']);
      let cssVars = '';
      Object.keys(shadedColors).forEach((colorName) => {
        Object.keys(colors[colorName]).forEach((shade) => {
          cssVars += `--${colorName}-${shade}: ${colors[colorName][shade]};`;
        });
      });
      return unsafeCSS(cssVars);
    }
  )()}
`;
