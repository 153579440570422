import { useEffect } from 'react';
import _ from 'lodash';
import { store } from '../store.js';
import useStoreValues from '../use-store-values.js';
import * as storeActions from '../store-actions.js';
import useSirenEntity from '../use-siren-entity.ts';

const acceptInvitePath = '/account/invites';

/**
 * Handles a number of side-effects driven from the user's root entity (/).
 *
 * Populates the store values for:
 *  - isProducer
 *  - primaryOrgHref
 *  - currentUserHref
 *
 *  Sets the store's currentOrgHref value to primaryOrgHref if none is currently set
 *
 *  Clears currentOrgHref and redirects to the invites tab if the user has no enrolled orgs and has a pending invite.
 */
export const useRootEntityEffects = ({ apiRootHref }) => {
  const {
    token,
    currentOrgHref,
    primaryOrgHref: storedPrimaryOrgHref,
    rootOrganizationHref: storedRootOrganizationHref,
    currentUserHref: storedCurrentUserHref,
    personas: storedPersonas,
  } = useStoreValues([
    'token',
    'currentOrgHref',
    'primaryOrgHref',
    'rootOrganizationHref',
    'currentUserHref',
    'personas',
  ]);

  const { entity: rootEntity, loading: rootLoading } = useSirenEntity({ href: token && apiRootHref });
  const currentUserHref = rootEntity?.getLinkByRel('https://api.binsentry.com/rel/current-user')?.href;
  const { entity: currentUserEntity } = useSirenEntity({ href: currentUserHref });
  const { entity: userEnrollmentsEntity } = useSirenEntity({ href: currentUserEntity?.getSubEntityByRel('https://api.binsentry.com/rel/user-enrollments')?.href });
  const { entity: userInvitesEntity } = useSirenEntity({ href: currentUserEntity?.getSubEntityByRel('https://api.binsentry.com/rel/user-invites')?.href });
  const { entity: currentOrgEntity, loading: currentOrgLoading } = useSirenEntity({ href: currentOrgHref });
  const primaryOrgHref = rootEntity?.getLinkByRel('https://api.binsentry.com/rel/primary-organization')?.href;
  const { entity: primaryOrgEntity } = useSirenEntity({ href: primaryOrgHref });

  // We are determining two things:
  // 1) Are you a producer
  // 2) What is your primary organization href
  useEffect(() => {
    if (!rootEntity || !currentUserEntity) {
      return;
    }

    // decides personas based on enrollments
    if (!userEnrollmentsEntity) {
      // not loaded yet
      return;
    }

    const personas = _
      .chain(userEnrollmentsEntity.getSubEntities('https://api.binsentry.com/rel/user-enrollment'))
      .map(enrollment => {
        const { properties } = enrollment?.getSubEntity('https://api.binsentry.com/rel/role') || {};
        // the persona is something we might promote to be metadata directly on the role, similar to isProducer
        // but more generic.  Until we have that, we do some more complex interrogation
        if (properties?.isProducer) {
          return 'producer';
        }

        if (properties?.name === 'service-partner') {
          return 'service-partner';
        }

        return 'admin';
      })
      .uniq()
      .sort() // sorted only for stability
      .value();

    if (!_.isEqual(personas, storedPersonas)) {
      store.dispatch(storeActions.setPersonas(personas));
    }
    if (!currentOrgHref) {
      store.dispatch(storeActions.changeOrg(primaryOrgHref));
    }
    if (storedPrimaryOrgHref !== primaryOrgHref) {
      store.dispatch(storeActions.setPrimaryOrg(primaryOrgHref));
    }
    const rootOrganizationHref = rootEntity?.getLinkByRel('https://api.binsentry.com/rel/root-organization')?.href;
    if (storedRootOrganizationHref !== rootOrganizationHref) {
      store.dispatch(storeActions.setRootOrganization(rootOrganizationHref));
    }
    if (storedCurrentUserHref !== currentUserHref) {
      store.dispatch(storeActions.setCurrentUser(currentUserHref));
    }
  }, [
    rootEntity,
    currentUserHref,
    storedCurrentUserHref,
    currentUserEntity,
    userEnrollmentsEntity,
    primaryOrgEntity,
    primaryOrgHref,
    storedPrimaryOrgHref,
    currentOrgHref,
    storedPersonas,
    storedRootOrganizationHref,
  ]);

  // If user is not enrolled in any organization and an invite exists, redirect them to accept the invites
  useEffect(() => {
    const noEnrollments = userEnrollmentsEntity && !userEnrollmentsEntity.getSubEntity('https://api.binsentry.com/rel/user-enrollment');
    if (noEnrollments && userInvitesEntity?.hasSubEntityByRel('item') && !window.location.href.includes(acceptInvitePath)) {
      // No permissions on anything anyways - unsetting works around caching issue when using the same browser for multiple users
      store.dispatch(storeActions.changeOrg(undefined));
      store.dispatch(storeActions.navigate(acceptInvitePath));
    }
  }, [userInvitesEntity, userEnrollmentsEntity]);

  // If user cannot access currentOrg, set it to primary org.
  useEffect(() => {
    if (currentOrgLoading || rootLoading) {
      return;
    }
    if (!currentOrgEntity) {
      const primaryOrgHref = rootEntity?.getLinkByRel('https://api.binsentry.com/rel/primary-organization')?.href;
      store.dispatch(storeActions.changeOrg(primaryOrgHref));
    }
  }, [currentOrgEntity, currentOrgLoading, rootEntity, rootLoading]);
};
