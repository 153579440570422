import { css } from '~/lib/bn-lit-element';

export const fonts = css`
  @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700");

  :root,
  * {
    --heading-font: "Nunito Sans", sans-serif;
    --content-font: "Nunito Sans", sans-serif;
  }
`;
