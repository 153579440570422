import { css } from '~/lib/bn-lit-element';

export const formStyles = css`
.form-field {
  margin: 0 0 1em;
}

.form-field + .form-actions {
  margin-top: 1em;
}

label {
  color: var(--gray-80);
  font-weight: normal;
  font-size: .9em;
  font-family: var(--content-font);
}

label[for] {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.property-group + .property-group,
.field-group + .field-group {
  margin-top: 1.5em;
}

input[type=text],
input[type=search],
input[type=tel],
input[type=email],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=password],
input[type=number],
select,
textarea {
  background-color: transparent;
  box-sizing: border-box;
  color: var(--black-base);
  font-family: var(--content-font);
  font-size: 1em;
  line-height: 1.5em;
  padding: 3px;
  width: 100%;

  &:focus-visible {
    outline: 2px solid var(--blue-50);
  }
}

input[type=text]:disabled,
input[type=search]:disabled,
input[type=tel]:disabled,
input[type=email]:disabled,
input[type=date]:disabled,
input[type=datetime]:disabled,
input[type=datetime-local]:disabled,
input[type=password]:disabled,
input[type=number]:disabled,
select:disabled,
textarea:disabled {
  background-color: var(--gray-20);
  border: 1px solid var(--gray-50);
  color: var(--gray-70);
}

input[type=checkbox],
input[type=radio],
input[type=range] {
  accent-color: var(--blue-50);
}

.input-wrapper {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.input-wrapper input {
  border: 1px solid var(--gray-60);
  flex-grow: 1;
}

.suffix {
  color: black;
  margin-left: .5em;
  padding: 4px;
}

.list-actions {
  padding: 8px;
  text-align: center;
}

.list-actions .button {
  margin: 4px;
}
`;
