import classNames from 'classnames';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Autocomplete, Button, makeStyles, Popover, TextField, Icons } from '~/lib/bn-material-ui';
import {
  store,
  storeActions,
  useFullSirenCollection,
  useOrganizationDescendantsSearchHref,
  useSirenEntity,
  useStoreValues,
} from '~/utility/BinSentry-ui-utility';
import { useBnMUIVariants } from '~/utility/styles/bn-mui-variants';

const useStyles = makeStyles(({ palette }) => {
  return {
    popover: {
      width: 400,
      overflow: 'visible',
      '& .MuiInputLabel-shrink': {
        paddingLeft: 5,
      },
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    selectorPaper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    selectMillsButton: {
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      padding: '0 0.5rem',
      borderRadius: 2,
      minWidth: 150,
      minHeight: 25,
      outline: 'none !important', // to overwrite the mui theme
    },
    input: {
      paddingLeft: 15,
    },
    textField: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      margin: '0.125rem 0',
      '& .MuiSvgIcon-root': {
        fill: palette.gray[60],
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiInput-root:after': {
        borderBottomColor: palette.primary.main,
      },
    },
  };
});

const SELECTABLE_ORG_TYPES = {
  'billing-account': {
    groupName: 'Billing Accounts',
    precedence: 1,
  },
  'account': {
    groupName: 'Mills',
    precedence: 3,
  },
  'service-account': {
    groupName: 'Service Accounts',
    precedence: 2,
  },
  'service-partner': {
    groupName: 'Service Partners',
    precedence: 5,
  },
  'sales-account': {
    groupName: 'Sales Accounts',
    precedence: 4,
  },
};

const SHORTCUT_KEY = '.';

const selectableOrgTypesByPersona = {
  'service-partner': ['service-partner'],
  'producer': [],
  'admin': Object.keys(SELECTABLE_ORG_TYPES),
};

function useSelectableOrgsSearchHref(limit, primaryOrgHref) {
  const { personas } = useStoreValues(['personas']);
  const organizationTypes = personas?.flatMap(persona => selectableOrgTypesByPersona[persona] || []);
  return useOrganizationDescendantsSearchHref({ limit, organizationTypes, baseOrganizationHref: primaryOrgHref });
}

function MillSelector({ href, apiRoot }) {
  const styles = useStyles();
  const bnMuiVariants = useBnMUIVariants();
  const { entity: root } = useSirenEntity({ href: apiRoot });
  const primaryOrgHref = root?.getLink('https://api.binsentry.com/rel/primary-organization')?.href;
  const { entity: currentOrg } = useSirenEntity({ href });
  const { searchHref: countSearchHref } = useSelectableOrgsSearchHref(0, primaryOrgHref);
  const { searchHref } = useSelectableOrgsSearchHref(500, primaryOrgHref);

  const buttonRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [inputRef, setInputRef] = useState();
  const { entity: countEntity } = useSirenEntity({ href: countSearchHref });
  const { collection: primaryOrgChildren } = useFullSirenCollection({ href: searchHref, onlyLoadFirstPage: false });

  const id = open ? 'mill-selector-popover' : undefined;

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleKeydown = useCallback((event) => {
    if (
      event.composedPath().filter((n) => ['INPUT', 'TEXTAREA'].includes(n.tagName))[0]
      || event.key !== SHORTCUT_KEY
      || open
    ) {
      return;
    }
    event.preventDefault();
    setAnchorEl(buttonRef.current);
  }, [buttonRef, open]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  useEffect(() => {
    if (open && inputRef) {
      inputRef.focus();
    }
  }, [open, inputRef]);

  // Wait until we load the count.  If there is only 1 child, don't show this component.
  if (!currentOrg || !countEntity || countEntity.properties.totalCount < 2) {
    return null;
  }

  const options = primaryOrgChildren
    .filter(item => item.getLink('via').href !== primaryOrgHref)
    .map(item => {
      const orgTypeName = item.getSubEntityByRel('https://api.binsentry.com/rel/organization-type')?.properties.name;
      const { groupName, precedence } = SELECTABLE_ORG_TYPES[orgTypeName] || { precedence: 0 };
      return {
        name: item.properties.name,
        id: item.getLink('via').href,
        orgTypeDisplayName: groupName,
        orgTypePrecedence: precedence,
      };
    });

  // we have to sort to support the grouping functionality
  options.sort((a, b) => {
    if (a.orgTypePrecedence < b.orgTypePrecedence) {
      return -1;
    }
    if (a.orgTypePrecedence > b.orgTypePrecedence) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  const handleChange = (event, selected, reason) => {
    if (reason === 'selectOption') {
      const selectedOrgId = selected?.id;
      // If the selected org is empty, unset the current org (if View All is selected).
      store.dispatch(storeActions.changeOrg(selectedOrgId));
      // The timeout allows the above changeOrg to start in ui *before* the navigate happens.
      setTimeout(() => store.dispatch(storeActions.navigate('/')), 1);
      handleClose();
    }
  };

  const orgSelected = primaryOrgHref !== href;
  if (orgSelected) {
    options.unshift({ name: 'View All' });
  }

  return <>
    <Button
      ref={buttonRef}
      size='small'
      className={classNames(styles.selectMillsButton, bnMuiVariants.containedButtonLight)}
      onClick={handleClick}
      endIcon={<Icons.ArrowDropDown/>}
      disableFocusRipple
      variant='contained'
    >
      {orgSelected ? currentOrg?.properties.name : 'Select Mill'}
    </Button>

    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={{ paper: styles.popover }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Autocomplete
        disablePortal
        open
        autoHighlight
        id='combo-select-mill'
        options={options}
        classes={{ paper: styles.selectorPaper }}
        groupBy={option => option.orgTypeDisplayName}
        getOptionLabel={option => option.name}
        onClose={handleClose}
        onChange={handleChange}
        renderInput={(params) =>
          <TextField
            className={styles.textField}
            {...params}
            InputProps={{
              ...params.InputProps,
              className: styles.input,
            }}
            inputRef={i => {setInputRef(i);}}
            label='Select Mill' />}
      />
    </Popover>
  </>;
}

export default MillSelector;
