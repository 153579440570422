import { LitEntity, html, css } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';
import './bn-icon';
class BnModal extends LitEntity {
  static get properties() {
    return {
      hide: { type: Boolean, reflect: true },
      noscroll: { type: Boolean },
      id: { type: String, reflect: true },
      escapeDisabled: { type: Boolean, attribute: 'escape-disabled' },
      closeDisabled: { type: Boolean, attribute: 'close-disabled' },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        visibility: visible;
        pointer-events: all;
      }
      :host([hide]) {
        visibility: hidden;
        pointer-events: none;
      }
      :host([hide]) .modal-backdrop {
        background-color: rgba(0,0,0,0);
      }
      :host([hide]) .modal-box {
        top: -10px;
      }
      .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,.6);
        transition: background-color .2s;
      }
      .modal-box {
        border-radius: 2px;
        box-sizing: border-box;
        position: relative;
        top: 0;
        margin: var(--modal-margin, 4px);
        min-height: var(--modal-min-height, auto);
        max-height: var(--modal-max-height, 90%);
        min-width: var(--modal-min-width, 80%);
        max-width: var(--modal-max-width, 100%);
        box-shadow: rgba(0,0,0,.3) 0 0 9px 3px;
        display: flex;
        flex-direction: column;
        transition: top .2s, min-height 0.5s ease-in-out;
        background-color: var(--white-base);
      }
      :host([noscroll]) .modal-body {
        position: relative;
        overflow: hidden;
      }
      :host([noscroll]) .modal-body slot {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
      }
      .modal-header {
        display: flex;
        font-size: 1.5em;
        border-bottom: 1px solid var(--gray-40);
        align-items: center;
        background-color: var(--surface);
        padding: var(--modal-header-padding, 9px);
      }
      .title-box {
        flex-grow: 1;
        margin-right: 9px;
      }
      .modal-body {
        display: var(--modal-body-display, block);
        flex-grow: 1;
        padding: var(--modal-body--padding, 9px);
        overflow-y: var(--modal-body-overflow-y, auto);
        background-color: var(--modal-body-background, --white-base);
      }
      @media(max-width: 600px) {
        .modal-box {
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
        }
      }
    `];
  }

  render() {
    return html`
      <div class="modal-backdrop" @mousedown="${(e) => e.target.className === 'modal-backdrop' && this.close(e)}">
        <div class="modal-box">
          <div class="modal-header">
            <span class="title-box">
              <slot name="title"></slot>
            </span>
            <button class="button button--tertiary" @click="${this.close}" ?disabled="${this.closeDisabled}">
              <bn-icon name="far fa-times"></bn-icon>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this._keyup = this._keyup.bind(this);
    window.addEventListener('keyup', this._keyup);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('keyup', this._keyup);
  }

  close() {
    if (this.closeDisabled) {
      return;
    }
    this.hide = true;
    this.dispatchEvent(new CustomEvent('close'));
  }

  open() {
    this.hide = false;
  }

  _keyup(e) {
    if (e.key === 'Escape' && !this.escapeDisabled && !this.hide) {
      this.close();
      document.activeElement && document.activeElement.blur();
    }
  }
}

window.customElements.define('bn-modal', BnModal);
