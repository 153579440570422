import React from 'react';
import { Menu, Button, Badge, Typography, Box, makeStyles, Icons, IconButton } from '~/lib/bn-material-ui';
import { useSirenEntity, useStoreValues } from '~/utility/BinSentry-ui-utility';
import { useBnMUIVariants } from '~/utility/styles/bn-mui-variants';

const useStyles = makeStyles(() => {
  return {
    profileMenuButton: {
      width: '10em',
    },
    profileMenuContent: {
      marginLeft: '3em',
      marginRight: '3em',
      marginTop: '1em',
      marginBottom: '1em',
    },
    menuContainer: {
      '& .MuiMenu-paper': {
        overflow: 'visible',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          background: 'white',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 3,
        },
      },
    },
  };
});

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const styles = useStyles();
  const bnMuiVariants = useBnMUIVariants();
  const { currentUserHref } = useStoreValues(['currentUserHref']);
  const { entity: userEntity } = useSirenEntity({ href: currentUserHref });
  const { entity: userInvitesEntity } = useSirenEntity({ href: userEntity?.getSubEntityByRel('https://api.binsentry.com/rel/user-invites')?.href });

  const userInvitesCount = userInvitesEntity?.getSubEntitiesByRel('item').length;
  const defaultAccountHref = userInvitesCount ? '/account/invites' : '/account';

  const goToProfileButton = <Button
    href={defaultAccountHref}
    variant='outlined'
    className={styles.profileMenuButton}
    onClick={handleClose}
  >Go to Profile</Button>;

  return (
    <div>
      <IconButton
        className={bnMuiVariants.iconButtonLight}
        id='profile-button'
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='large'
      >
        {userInvitesCount ? <Badge badgeContent={userInvitesCount}>{<Icons.AccountCircle />}</Badge> : <Icons.AccountCircle />}
      </IconButton>
      <Menu
        id='profile-menu'
        className={styles.menuContainer}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box display='flex' alignItems='center' className={styles.profileMenuContent} flexDirection='column'>
          <Typography variant='h6'>{userEntity?.properties.name}</Typography>
          <Typography variant='body2'>{userEntity?.properties.email}</Typography>
        </Box>
        <Box display='flex' justifyContent='center' className={styles.profileMenuContent}>
          {(
            userInvitesCount
              ? <Badge badgeContent={userInvitesCount} overlap='rectangular'>{goToProfileButton}</Badge>
              : goToProfileButton
          )}
        </Box>
        <Box display='flex' justifyContent='center' className={styles.profileMenuContent}>
          <Button href='/logout' variant='outlined' color='error' className={styles.profileMenuButton} onClick={handleClose}>
            Log Out
          </Button>
        </Box>
      </Menu>
    </div>
  );
}
