import { html, renderIf } from '../utility/lit-entity';
import './bn-modal';

/**
 * # Be Careful!
 * Inheriting this class places all styling outside of the shadow root and
 * will override mui theming.
 * 
 * **Do not inject `sharedStyles`/`formStyles` into the static class css, or hardcode
 * styling on any native elements while using this mixin!**
 */
export const BnModalMixin = (superClass) => class extends superClass {
  constructor() {
    super();
    this.hide = true;
    this.id = null;
  }

  static get properties() {
    return {
      hide: { type: Boolean, reflect: true },
      noscroll: { type: Boolean },
      escapeDisabled: { type: Boolean, attribute: 'escape-disabled' },
      closeDisabled: { type: Boolean, attribute: 'close-disabled' },
      id: { type: String },
      preserve: { type: Boolean },
    };
  }

  createRenderRoot() {
    this.renderRoot = document.createElement('div');
    document.body.appendChild(this.renderRoot);
    return this.renderRoot;
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }

    if (document.body.contains(this.renderRoot)) {
      document.body.removeChild(this.renderRoot);
    }
  }

  // Override this function to render things within the modal
  // Two slots are available
  //   <slot name="title"></slot>
  //   <slot></slot>
  renderContents() {
    return html``;
  }

  render() {
    return html`
      <bn-modal
        .id="${this.id}"
        ?hide="${this.hide}"
        ?noscroll="${this.noscroll}"
        ?escape-disabled="${this.escapeDisabled}"
        ?close-disabled="${this.closeDisabled}"
        @close="${this.close}"
      >
        ${renderIf(!this.hide || this.preserve, () => this.renderContents())}
      </bn-modal>
    `;
  }

  close() {
    this.hide = true;
    this.dispatchEvent(new CustomEvent('close'));
  }
};
