
import { LitEntity, css, html } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';

class DeviceSearchResults extends LitEntity {
  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: block;
        }
        bn-card {
          padding-top: 0.75rem;
          max-width: 800px;
        }
        p {
          background-color: var(--white-base);
          margin-left: 0.5rem;
          margin-top: 0;
        }
      `,
    ];
  }

  render() {
    if (this.loading) {
      return html`<bn-loading></bn-loading>`;
    }
    if (!this.entity || !this.entity.properties.deviceId) {
      return html`<p>
        Enter your device ID above (or scan your device's QR code) to navigate
        to the device page.
      </p>`;
    }
    return html`
      <bn-card
        .href="${`/devices/show?deviceId=${encodeURIComponent(this.href)}`}"
        @click="${() => this.dispatchEvent(new CustomEvent('select'))}"
      >
        <h3>Found Device</h3>
        <device-details .href="${this.href}"></device-details>
      </bn-card>
    `;
  }
}

window.customElements.define('device-search-results', DeviceSearchResults);
