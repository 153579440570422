import { css } from '~/lib/bn-lit-element';

/**
 * All button styling matching MuiButton theme, default to
 * medium sizing.
 *
 * - button = MuiButton-root
 * - button--text = MuiButton-text
 * - button--plain = MuiButton-text
 * - button--contained = MuiButton-containedPrimary
 * - button--outlined = MuiButton-outlinedPrimary
 * - button--tertiary = BnMuiVariant > outlinedTertiary
 * - button--containedError = MuiButton-containedError
 * - button--outlinedError = MuiButton-outlinedError
 */
export const buttonStyles = css`
.button {
  background-color: var(--gray-40);
  border-radius: 4px;
  border: 1px solid var(--gray-50);
  box-shadow: none !imoprtant
  color: var(--black-base);
  cursor: pointer;
  display: inline-block;
  font-family: var(--content-font, sans-serif);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
  min-height: 34px;
  transition: 0.2s color, 0.2s border-color, 0.2s background-color;
  text-decoration: none;
  text-shadow: unset;
}
.button[full-width] {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.button:disabled,
.button.disabled {
  background-color: var(--gray-20);
  border-color: var(--gray-40);
  color: var(--gray-70);
  cursor: default;
  text-shadow: none;
}
.button.ghost {
  text-shadow: none;
  box-shadow: none;
  background: transparent;
}
.button::-moz-focus-inner {
  border: 0;
}


.button--text,
.button--plain {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  color: var(--blue-50);
  text-decoration: none;
}
.button--text:hover:enabled,
.button--plain:hover:enabled {
  background-color: var(--blue-10);
  color: var(--blue-60);
}
.button--text:active:enabled,
.button--plain:active:enabled {
  background-color: var(--blue-20);
  color: var(--blue-60);
}
.button--text:focus:enabled,
.button--plain:focus:enabled {
  border-color: var(--blue-50);
}
.button--text:disabled,
.button--plain:disabled {
  border-width: 0px;
  color: var(--gray-60);
  opacity: 0.6;
}


.button--contained {
  background-color: var(--blue-10);
  border-color: var(--blue-50);
  border-width: 1px;
  border-style: solid;
  color: var(--blue-60);
}
.button--contained:hover:enabled {
  background-color: var(--blue-20);
}
.button--contained:active:enabled {
  background-color: var(--blue-30);
}
.button--contained:focus:enabled {
  outline-offset: 3px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--blue-50);
}
.button--contained:disabled {
  background-color: var(--gray-40);
  border-width: 0px;
  color: var(--gray-70);
  opacity: 0.6;
}


.button--outlined {
  background-color: transparent;
  border-color: var(--blue-50);
  border-width: 1px;
  border-style: solid;
  color: var(--blue-50);
}
.button--outlined:hover:enabled {
  background-color: var(--blue-10);
}
.button--outlined:active:enabled {
  background-color: var(--blue-10);
}
.button--outlined:focus:enabled {
  outline-offset: 3px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--blue-50);
}
.button--outlined:disabled {
  border-color: var(--gray-60);
  color: var(--gray-60);
  opacity: 0.6;
}


.button--tertiary {
  background-color: transparent;
  border-color: var(--gray-70);
  border-width: 1px;
  border-style: solid;
  color: var(--gray-70);
}
.button--tertiary:hover:enabled {
  background-color: var(--gray-30);
}
.button--tertiary:active:enabled {
  background-color: var(--gray-40);
}
.button--tertiary:focus:enabled {
  outline-offset: 3px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--gray-70);
}
.button--tertiary:disabled {
  background-color: transparent;
  border-color: var(--gray-70);
  color: var(--gray-70);
  opacity: 0.6;
}


.button--containedError {
  background-color: var(--red-20);
  border-width: 0px;
  color: var(--white-base);
}
.button--containedError:hover:enabled {
  background-color: var(--red-30);
}
.button--containedError:active:enabled {
  background-color: var(--red-30);
}
.button--containedError:focus:enabled {
  outline-offset: 3px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--red-20);
}
.button--containedError:disabled {
  background-color: transparent;
  border-color: var(--gray-60);
  border-width: 1px;
  color: var(--gray-60);
}


.button--outlinedError {
  background-color: transparent;
  border-color: var(--red-20);
  border-width: 1px;
  border-style: solid;
  color: var(--red-20);
}
.button--outlinedError:hover:enabled {
  background-color: var(--red-10);
  border-color: var(--red-30) !important;
  color: var(--red-30);
}
.button--outlinedError:active:enabled {
  background-color: var(--red-10);
  border-color: var(--red-30);
}
.button--outlinedError:focus:enabled {
  outline-offset: 3px;
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--red-20);
  border-color: var(--red-20);
}
.button--outlinedError:disabled {
  border-color: var(--gray-60);
  color: var(--gray-60);
}
`;
