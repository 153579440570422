import { LitElement, html, css, svg } from '~/lib/bn-lit-element';
import { sharedStyles } from '../../styles/shared-styles';
import { LevelColours } from '../../utility/level-colours-mixin';
import '../../common/bn-icon';

const PROFILE = {
  CENTER_DISCHARGE: 1,
  SIDE_DISCHARGE: 2,
};

export const SCALE = 20;

const observer = new IntersectionObserver(function (entries) {
  entries.forEach(entry => entry.isIntersecting && entry.target.updateDimensions());
});

export class BinProfileSvg extends LitElement {
  constructor() {
    super();
    this.height = 100;
    this.width = 100;
    this.padding = 3;
    this.additionalLegHeight = 3;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    observer.disconnect();
  }

  static get properties() {
    return {
      points: { type: Array, attribute: false },
      // element attributes
      binvolumes: { type: Object },
      bindimensions: { type: Object },
      binlevel: { type: Object },
      nodevice: { type: Boolean },
      basic: { type: Boolean },
      hideRings: { type: Boolean },
      padding: { type: Number },
      additionalLegHeight: { type: Number, attribute: 'additional-leg-height' },
      // manually set volumes to render
      volume: { type: Number },
      volumes: { type: Array },
      medicated: { type: Boolean },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host{
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
      }
      line.rivets {
        stroke: var(--gray-70);
        stroke-dasharray: 1 2;
      }
      line.legs {
        stroke: var(--gray-50);
        stroke-dasharray: none;
      }
      :host([medicated]) line.legs {
        stroke: var(--red-20);
        stroke-dasharray: none;
      }
      text {
        stroke: none;
        user-select: none;
        font-weight: var(--bin-profile-font-weight, initial);
        font-size: var(--bin-profile-font-size, 8px);
        fill: var(--bin-profile-font-color, var(--black-base));
        transition: fill .2s, font-size .2s;
      }
      text.outline {
        stroke: var(--white-base);
        stroke-width: 2px;
        stroke-linejoin: round;
      }
      polygon.bin {
        fill: var(--gray-40);
      }
      :host([medicated]) polygon.bin {
        fill: var(--red-10);
      }
      polygon.outline {
        stroke: var(--gray-60);
        fill: none;
      }
      :host([medicated]) polygon.outline {
        stroke: var(--red-20);
      }
      .rings {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        text-align: center;
      }
      .bin-top,
      .bin-ring {
        border-bottom: 1px dotted rgba(0,0,0,.3);
        margin-bottom: -.5px;
      }
      .bin-section {
        font-size: 8pt;
        text-shadow: 0px 0px 1px white;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .contents {
        position: absolute;
        bottom: 0px;
        height: var(--contents-height);
        width: 100%;
        background-color: var(--blue-60, blue);
        z-index: 1;
        opacity: 1;
        transition: height .2s;
      }
      feFlood {
        flood-opacity: 1;
        flood-color: var(--blue-60);
      }
      rect.low {
        fill: var(--fill-level-low);
      }
      rect.medium  {
        fill: var(--fill-level-medium);
      }
      rect.high  {
        fill: var(--fill-level-high);
      }
      .volume0 {
        fill: var(--blue-60);
      }
      .volume1 {
        fill: var(--blue-50);
      }
      .feed {
        opacity: 1;
      }
      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: var(--blue-40);
        box-sizing: border-box;
        box-shadow: inset 0 0 3px var(--blue-50);
        color: var(--blue-50);
      }
    `];
  }

  firstUpdated() {
    this.height = this.clientHeight;
    this.width = this.clientWidth;
    observer.observe(this);
  }

  updateDimensions() {
    if (this.height === this.clientHeight && this.width === this.clientWidth) {
      return;
    }
    this.height = this.clientHeight;
    this.width = this.clientWidth;
    this.requestUpdate();
  }

  render() {
    if (this.basic && this.nodevice) {
      return html`
        <div class="no-data">
          <bn-icon name="fas fa-question-circle"></bn-icon>
        </div>
      `;
    }

    return html`
      ${this._renderBin()}
    `;
  }

  _renderBin() {
    this.points = this._getPoints();
    if (!this.points) {
      return svg``;
    }
    const padding = this.basic ? 0 : this.padding;
    const width = this.points[3].x + padding;
    const height = this.points[4].y + padding;
    const polyPoints = this.points.map((p) => `${p.x},${p.y}`).join(' ');
    return svg`
      <svg viewBox="0 0 ${width} ${height}" width="100%" height="100%">
        <clipPath id="profile-clip">
          <polygon points="${polyPoints}"></polygon>
        </clipPath>
        <polygon class="bin" points="${polyPoints}"></polygon>
        ${this._renderFill()}
        ${this._renderLegs()}
        ${this._renderRings()}
        <polygon class="outline" points="${polyPoints}"></polygon>
      </svg>
    `;
  }

  _renderFill() {
    const heights = this._getHeights();
    const padding = this.basic ? 0 : this.padding;
    const width = this.points[3].x + padding;
    return svg`
      ${heights.map((v, i) => svg`
        <rect x="0" y="${v.y}" width="${width}" height="${v.h}" clip-path="url('#profile-clip')" class="feed volume${i} ${v.state}"></rect>
      `)}
    `;
  }

  _renderLegs() {
    if (this.basic || !this.points) {
      return svg``;
    }
    const p1 = this.points[6];
    const p2 = this.points[3];
    const bottom = this.points[4].y + this.additionalLegHeight;
    return svg`
      <line class="legs" x1="${p1.x}" y1="${p1.y}" x2="${p1.x}" y2="${bottom}"></line>
      <line class="legs" x1="${p2.x}" y1="${p2.y}" x2="${p2.x}" y2="${bottom}"></line>
    `;
  }

  _renderRings() {
    if (!this.bindimensions || this.hideRings || !this.points) {
      return svg``;
    }
    const rings = [];
    const { ringCount, top, hopper } = this.bindimensions;
    // get the top left and bottom right points of the body
    // p1 *---*
    //    |   |
    //    *---* p2
    const p1 = this.points[7];
    const p2 = this.points[3];
    const left = p1.x + 1;
    const right = p2.x - 1;
    const ringHeight = (p2.y - p1.y) / ringCount;
    if (top.height) {
      rings.push(svg`<line class="rivets" x1="${left}" y1="${p1.y}" x2="${right}" y2="${p1.y}"></line>`);
    }
    // and body segments
    const dx = Number(p2.x) - Number(p1.x);
    const x = Number(p1.x) + (dx / 2);
    for (let i = 0; i < ringCount; ++i) {
      const y = Number(p1.y) + (i * ringHeight);
      rings.push(svg`<line class="rivets" x1="${left}" y1="${y}" x2="${right}" y2="${y}"></line>`);
      rings.push(svg`<text class="outline" text-anchor="middle" x="${x}" y="${y + (ringHeight / 2) + 3}">${ringCount - i}</text>`);
      rings.push(svg`<text text-anchor="middle" x="${x}" y="${y + (ringHeight / 2) + 3}">${ringCount - i}</text>`);
    }
    // add hopper
    if (hopper.height) {
      rings.push(svg`<line class="rivets" x1="${left}" y1="${p2.y}" x2="${right}" y2="${p2.y}"></line>`);
    }
    return rings;
  }

  _getPoints() {
    if (this.basic) {
      return this._getBasicPoints();
    }

    if (!this.bindimensions) {
      return;
    }

    const { profile, totalHeight, top, body, hopper } = this.bindimensions;
    if (!top || !body || !hopper) {
      return;
    }

    // calculate dimensions
    const h = totalHeight;
    const w = (body.radius || 0) * 2;
    const t = (top.truncatedRadius || 0) * 2;
    const b = (hopper.truncatedRadius || 0) * 2;
    const ht = top.height || 0;
    const hb = hopper.height || 0;

    // plot coordinates of the bin
    // + x
    // y        t
    //       |-----|
    //      0       1        -      -
    //       .--=--.         | ht   |
    //   7 .`-------`. 2     -      |
    //     |         |       | hm   | h
    //   6 . _______ . 3     -      |
    //       `.___.`         | hb   |
    //      5       4        -      -
    //        |___|
    //          b
    //     |_________|
    //          w
    /* eslint-disable no-multi-spaces */
    const points = [
      { x: (w - t) / 2, y: 0 },
      { x: (w - t) / 2 + t, y: 0 },
      { x: w, y: ht },
      { x: w, y: h - hb },
      { x: (w - b) / 2 + b, y: h },
      { x: (w - b) / 2, y: h },
      { x: 0, y: h - hb },
      { x: 0, y: ht },
    ];
    /* eslint-enable no-multi-spaces */
    // adjust for profile
    if (profile === PROFILE.SIDE_DISCHARGE) {
      points[4].x = b;
      points[5].x = 0;
    }
    // offset
    const o = {
      x: this.padding,
      y: this.padding,
    };
    // transform
    points.forEach((p) => {
      p.x = Math.round(o.x + p.x * SCALE);
      p.y = Math.round(o.y + p.y * SCALE);
    });
    return points;
  }

  _getBasicPoints() {
    const w = this.width;
    const h = this.height;
    const points = [
      { x: 0, y: 0 },
      { x: w, y: 0 },
      { x: w, y: 0 },
      { x: w, y: h },
      { x: w, y: h },
      { x: 0, y: h },
      { x: 0, y: h },
      { x: 0, y: 0 },
    ];
    const volumes = this.binvolumes || {};
    const topHeight = (volumes.top / volumes.total) * h;
    const hopperHeight = ((volumes.total - volumes.hopper) / volumes.total) * h;
    points[2].y = points[7].y = topHeight || 0;
    points[3].y = points[6].y = hopperHeight || h;
    return points;
  }

  _getHeights() {
    const top = this.points[0].y;
    const bottom = this.points[4].y;
    const SVGHeight = bottom - top;
    const binHeight = this.bindimensions && this.bindimensions.totalHeight;

    if (this.volumes) {
      return this._getHeightsFromVolume({ SVGHeight, bottom, binHeight });
    }

    let fractionOfTotalVolume = 0;
    let fractionOfFullVolume = 0;
    let fractionOfTotalHeight = 0;

    const volumeProvided = typeof this.volume === 'number' && this.bindimensions;
    if (this.binlevel || volumeProvided) {
      let volume;
      let feedHeight;
      if (volumeProvided) {
        ({ volume } = this);
        feedHeight = this._calculateFeedHeightFromVolume(volume);
      } else {
        ({ volume, feedHeight } = this.binlevel);
      }
      fractionOfTotalVolume = volume / this.binvolumes.total;
      fractionOfFullVolume = volume / this.binvolumes.fullTotal;
      fractionOfTotalHeight = feedHeight / binHeight;
    }

    const level = this.basic ? fractionOfTotalVolume : fractionOfTotalHeight;
    const threshold = LevelColours.getThreshold(fractionOfFullVolume * 100);
    const state = threshold.name;
    const contentHeight = level * SVGHeight;
    const volumes = [
      { h: contentHeight, y: bottom - contentHeight, state },
    ];
    return volumes;
  }

  _getBinSections() {
    return [
      {
        name: 'hopper',
        sectionVolume: this.binvolumes.hopper,
        sectionHeight: this.bindimensions.hopper.height,
        coneHeight: this.bindimensions.hopper.height,
        bottomRadius: this.bindimensions.hopper.truncatedRadius,
        topRadius: this.bindimensions.body.radius,
        calculate: this._getConeHeight,
      },
      {
        name: 'body',
        sectionVolume: this.binvolumes.body,
        sectionHeight: this.bindimensions.body.height,
        calculate: this._getCylinderHeight,
      },
      {
        name: 'top',
        sectionVolume: this.binvolumes.top,
        sectionHeight: this.bindimensions.top.height,
        coneHeight: this.bindimensions.top.height,
        bottomRadius: this.bindimensions.body.radius,
        topRadius: this.bindimensions.top.truncatedRadius,
        calculate: this._getConeHeight,
      },
    ];
  }

  _getHeightsFromVolume({ SVGHeight, bottom, binHeight }) {
    const sections = this._getBinSections();
    const heights = [];
    let y = bottom;
    let rollingVolume = 0;
    let rollingHeight = 0;
    for (const v of this.volumes) {
      rollingVolume += v;
      if (isNaN(v)) {
        continue;
      }
      let totalHeight = this._calculateFeedHeightFromVolume(rollingVolume, sections);
      const additionalHeight = (totalHeight - rollingHeight);
      const level = additionalHeight / binHeight;
      const h = level * SVGHeight;
      y = y - h;
      heights.push({ h, y });
      rollingHeight += additionalHeight;
    }
    return heights;
  }

  _calculateFeedHeightFromVolume(volume, sections = this._getBinSections()) {
    let totalHeight = 0;
    let remainingVolume = volume;
    for (const section of sections) {
      const results = this._getSectionHeight(remainingVolume, section);
      totalHeight += results.additionalHeight;
      if (results.remainingVolume <= 0) {
        break;
      }
      // eslint-disable-next-line prefer-destructuring
      remainingVolume = results.remainingVolume;
    }

    return totalHeight;
  }

  _getCylinderHeight({ volume, sectionVolume, sectionHeight }) {
    return volume / sectionVolume * sectionHeight;
  }

  _getConeHeight({ volume, sectionHeight: coneHeight, bottomRadius, topRadius }) {
    return (-coneHeight * bottomRadius + Math.pow(coneHeight, 2 / 3) *
      ((coneHeight * Math.pow(bottomRadius, 3) + ((3 * (topRadius - bottomRadius) * volume) / Math.PI)) ** (1 / 3))) /
      (topRadius - bottomRadius);
  }

  _getSectionHeight(inputVolume, { calculate, sectionVolume, sectionHeight, bottomRadius, topRadius }) {
    if (inputVolume <= 0) {
      return { remainingVolume: 0, additionalHeight: 0 };
    }

    if (inputVolume > sectionVolume) {
      return {
        remainingVolume: inputVolume - sectionVolume,
        additionalHeight: sectionHeight,
      };
    }

    const additionalHeight = calculate({
      volume: inputVolume,
      sectionVolume,
      sectionHeight,
      bottomRadius,
      topRadius,
    });

    return { remainingVolume: 0, additionalHeight };
  }
}

window.customElements.define('bin-profile-svg', BinProfileSvg);
