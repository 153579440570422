import { css } from '~/lib/bn-lit-element';

export const main = css`
  :root {
    --background: var(--gray-20, #f3f3f3);
    --primary: var(--primary, pink);
    --secondary: var(--blue-50, mediumblue);
    --surface: var(--white-base, white);
    --error: var(--red-20, red);
    --on-primary: var(--white-base, white);
    --on-secondary: var(--black-base, black);
    --on-surface: var(--black-base, black);
    --on-error: var(--white-base, white);
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: var(--content-font, sans-serif);
  }

  .splash {
    background-color: var(--surface, white);
    max-width: 300px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    text-align: center;
  }
`;
