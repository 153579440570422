import { css, html, LitEntity, renderIf, SirenActionMixin } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../props/bin-name';
import '../props/bin-owner';
import './bin-list-item';
import '../../common/bn-load-more-button';

class BinList extends SirenActionMixin(LitEntity) {
  constructor() {
    super();
    this.bins = [];
    this.sortActions = [];
    this.referencePoint = {};
  }

  static get properties() {
    return {
      bins: { type: Array, attribute: false },
      isFetching: { type: Boolean, attribute: false },
      sortActions: { type: Array, attribute: false },
      referencePoint: { type: Object, attribute: 'reference-point' },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        display: block;
      }
      p {
        text-align: center;
        color: var(--gray-80);
      }
      .list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    `];
  }

  render() {
    if (this.loading) {
      return html`<bn-loading></bn-loading>`;
    }

    if (!this.bins || this.bins.length === 0) {
      return html`<p>No Bins In This Organization</p>`;
    }

    return html`
      ${renderIf(this.sortActions && this.sortActions.length > 0, html`
        <div class="list-actions">
          ${this.sortActions.map(item => html`
            <button class="button button--tertiary" @click="${() => this.href = item.href}" ?disabled=${this.href === item.href}>${item.title}</button>
          `)}
        </div>
      `)}

      <div class="list">
        ${this.bins.map((bin) => html`<bin-list-item .href="${bin.href}" reference-point="${JSON.stringify(this.referencePoint)}"></bin-list-item>`)}
      </div>

      <bn-load-more-button .href="${this.href}" @moreloaded="${(e) => this._parseBins(e.detail.entity)}"></bn-load-more-button>
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    this.bins = [];
    if (!this.entity) {
      return;
    }
    this._parseBins(this.entity);
    this.sortActions = this.entity.getActionsByClass('sort');
  }

  _parseBins(entity) {
    if (!entity || !entity.entities) {
      return;
    }
    const bins = entity.entities.map((sub) => sub.getLinkByRel('self'));
    this.bins = [...this.bins, ...bins];
  }
}

window.customElements.define('bin-list', BinList);
