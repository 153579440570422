import moment from 'moment';
import { LitEntity, html, css, renderIf } from '../../utility/lit-entity';
import { sharedStyles } from '../../styles/shared-styles';
import '../../common/bn-icon';
import './bin-latest-level';
import './bin-profile-svg';

class BinProfile extends LitEntity {
  constructor() {
    super();
    this.padding = 3;
    this.additionalLegHeight = 3;
  }

  static get properties() {
    return {
      basic: { type: Boolean },
      hideRings: { type: Boolean },
      nodevice: { type: Boolean, reflect: true },
      volumes: { type: Array }, // manually set volumes to render
      height: { type: Number },
      width: { type: Number },
      padding: { type: Number },
      additionalLegHeight: { type: Number, attribute: 'additional-leg-height' },
      // latest bin level
      ignoreLatestLevel: { type: Boolean, attribute: 'ignore-latest-level' },
      level: { type: Object },
      levelVolume: { type: Number, attribute: false },
      levelfreshnessdate: { type: Object },
      isStale: { type: Boolean, attribute: false },
      medicated: { type: Boolean },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host{
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
      }
      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: var(--gray-50);
        box-sizing: border-box;
        box-shadow: inset 0 0 3px var(--gray-70);
        color: var(--blue-50);
      }
      .no-data .warning {
        color: var(--red-20);
        font-weight: bold;
        z-index: 11;
        text-align: center;
        line-height: 1.5em;
      }
      .no-data .warning bn-icon {
        color: var(--red-20);
      }
      .no-data bn-icon {
        color: var(--gray-60);
        font-size: 1.5em;
      }
      .no-data.overlay {
        position: absolute;
        background: none;
        z-index: 10;
      }
      .no-data.overlay .background {
        position: absolute;
        opacity: .8;
        height: 100%;
        width: 100%;
        background-color: var(--gray-20);
      }
    `];
  }

  render() {
    if (this.loading) {
      return html`
        <div class="no-data">
          <bn-icon name="fas fa-spinner fa-pulse"></bn-icon>
        </div>
      `;
    }

    if (this.basic && this.nodevice) {
      return html`
        <div class="no-data">
          <bn-icon name="fas fa-question-circle"></bn-icon>
        </div>
      `;
    }

    return html`
      ${renderIf(this.isStale, () => html`
        <div class="no-data overlay">
          <div class="background"></div>
          <p class="warning">
            <bn-icon name="fas fa-exclamation-circle"></bn-icon><br/>
            No reading
          </p>
        </div>
      `)}
      <bin-profile-svg
        ?basic="${this.basic}"
        ?nodevice="${this.nodevice}"
        ?hiderings="${this.hiderings}"
        additional-leg-height="${this.additionalLegHeight}"
        .binvolumes="${this.getProperty('volumes')}"
        .bindimensions="${this.getProperty('dimensions')}"
        .binlevel="${this.level}"
        .padding="${this.padding}"
        .volume="${this.levelVolume}"
        .volumes="${this.volumes}"
        ?medicated="${this.medicated}"
      ></bin-profile-svg>

      ${renderIf(!this.ignoreLatestLevel, html`
        <bin-latest-level .href="${this.href}" @levelUpdate="${this._latestLevelChanged}"></bin-latest-level>
      `)}
    `;
  }

  _entityChanged(entity) {
    super._entityChanged(entity);
    const { hasDevice, monitoringType, type } = this.entity?.properties || {};
    this.nodevice = !this.entity || (monitoringType !== 'unmonitored' && type !== 'aggregate' && !hasDevice);
  }

  _latestLevelChanged(e) {
    const { latestLevel: entity, volume } = e.detail;
    if (!entity) {
      this.level = undefined;
      this.levelVolume = undefined;
      return;
    }
    this.isStale = this.levelfreshnessdate && moment(entity.properties.publishedAt).isBefore(this.levelfreshnessdate);
    this.level = this.isStale ? undefined : entity.properties;
    this.levelVolume = this.level ? volume : undefined;
  }
}

window.customElements.define('bin-profile', BinProfile);
