import { alpha, makeStyles } from '~/lib/bn-material-ui';

/**
 * Contains the various binsentry variants for components whose
 * styling is out of the MUI theme scope, to address our antipattern
 * in component architecture.
 *
 * Ex: button variants against dark backgrounds
 */
const useBnMUIVariants = makeStyles(({ palette }) => ({
  /**
   * Contained buttons against dark backgrounds
   */
  containedButtonLight: {
    color: palette.white,
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    outlineWidth: '0px !important',

    '&:not(.Mui-disabled):hover': {
      backgroundColor: 'rgba(255,255,255,0.08)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:not(.Mui-disabled):focus': {

    },
    '&:not(.Mui-disabled):active': {
      backgroundColor: 'rgba(255,255,255,0.04)',
    },
    '&:disabled': {
      color: palette.gray[60],
      opacity: 0.5,
    },
  },
  /**
   * Outlined buttons against dark backgrounds
   */
  outlinedButtonLight: {
    color: palette.white,
    border: `1px solid ${palette.white}`,
    backgroundColor: 'transparent',
    outlineWidth: '0px !important',

    '&:not(.Mui-disabled):hover': {
      backgroundColor: 'rgba(255,255,255,0.08)',
      borderColor: palette.gray[30],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:not(.Mui-disabled):active': {
      backgroundColor: 'rgba(255,255,255,0.04)',
      borderColor: palette.gray[40],
    },
    '&:disabled': {
      color: palette.gray[60],
      borderColor: palette.gray[60],
      opacity: 0.5,
    },
  },
  /**
   * Alert button on info banners
   */
  infoAlertButton: {
    '& .MuiButton-root, &.MuiButton-root': {
      color: palette.blue[50],
      '&:not(.Mui-disabled):hover': {
        backgroundColor: palette.blue[20],
        color: palette.blue[60],
      },
      '&:not(.Mui-disabled):focus': {
        borderColor: palette.blue[50],
      },
    },
  },
  /**
   * Alert button on warning banners
   */
  warningAlertButton: {
    '& .MuiButton-root, &.MuiButton-root': {
      color: palette.yellow[30],
      '&:not(.Mui-disabled):hover': {
        backgroundColor: palette.yellow[10],
        color: palette.yellow[30],
      },
      '&:not(.Mui-disabled):focus': {
        borderColor: palette.yellow[30],
      },
    },
  },
  /**
   * Alert button on error banners
   */
  errorAlertButton: {
    '& .MuiButton-root, &.MuiButton-root': {
      color: palette.red[30],
      '&:not(.Mui-disabled):hover': {
        backgroundColor: alpha(palette.red[20], 0.25),
        color: palette.red[30],
      },
      '&:not(.Mui-disabled):focus': {
        borderColor: palette.red[20],
      },
    },
  },
  /**
   * Alert button on success banners
   */
  successAlertButton: {
    '& .MuiButton-root, &.MuiButton-root': {
      color: palette.green[30],
      '&:not(.Mui-disabled):hover': {
        backgroundColor: palette.green[10],
        color: palette.green[30],
      },
      '&:not(.Mui-disabled):focus': {
        borderColor: palette.green[20],
      },
    },
  },
  /**
   * The binsentry outlined gray buttons,
   * neither primary nor secondary
   */
  outlinedTertiary: {
    backgroundColor: 'transparent',
    border: `1px solid ${palette.gray[70]}`,
    color: palette.gray[70],

    '&:not(.Mui-disabled):hover': {
      // hover should override (focus)
      backgroundColor: `${palette.gray[30]}`,
    },
    '&:not(.Mui-disabled):active': {
      backgroundColor: palette.gray[40],
    },
    '&:not(.Mui-disabled):focus': {
      outlineOffset: 3,
      outline: `1px solid ${palette.gray[70]}`,
    },
    '&:disabled': {
      borderColor: palette.gray[70],
      color: palette.gray[70],
      opacity: 0.6,
    },
  },
  /**
   * Icon Buttons against dark backgrounds
   */
  iconButtonLight: {
    color: palette.white,
    border: '1px solid transparent',

    '&:not(.Mui-disabled):hover': {
      // hover should override (focus)
      backgroundColor: 'rgba(255,255,255,0.08)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:not(.Mui-disabled):active': {
      backgroundColor: 'rgba(255,255,255,0.04)',
    },
    '&:disabled': {
      color: palette.gray[60],
      opacity: 0.5,
    },
  },
  /**
   * Icon Buttons inside feed budget tables
   */
  iconButtonFullWidth: {
    padding: '0.5rem 0.25rem',

    '& .MuiIconButton-root': {
      border: `1px solid ${palette.blue[50]}`,
      color: palette.blue[50],
      borderRadius: 4,
      backgroundColor: palette.white,
    },
  },
  /**
   * All binsentry search text fields
   */
  outlinedSearchField: {
    borderColor: palette.gray[40],
    background: palette.white,
  },
  /**
   * Alert/Banner variant for cold weather
   */
  coldWeatherAlert: {
    backgroundColor: palette.teal[10],
    color: palette.teal[30],
  },
}));

export {
  useBnMUIVariants,
};
