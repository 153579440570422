import { css, html, LitEntity } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';

export class BnBreadCrumbs extends LitEntity {
  constructor() {
    super();
    this.items = [];
  }

  static get properties() {
    return {
      singleline: { type: Boolean },
      explicitlinks: { type: Boolean },
      items: { type: Array },
      renderFinalSeparator: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [sharedStyles, css`
      :host {
        display: inline;
        color: var(--black-base);
      }
      .crumb-container {
        width: 100%;
        display: inline-table;
      }
      .crumb {
        white-space: nowrap;
        text-decoration: none;
        color: var(--crumb-color, var(--black-base));
        max-width: var(--crumb-max-width, calc(100% - 1.1em));
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .crumb:last-child {
        max-width: 100%;
        overflow: var(--last-crumb-overflow, hidden);
      }
      .last {
        font-size: var(--last-crumb-font-size, inherit);
        font-weight: var(--last-crumb-font-weight, inherit);
      }
      :host([singleline]) .crumb-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
      :host([singleline]) .crumb {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      a.explicit-link {
        color: var(--blue-50);
        text-decoration: underline;
      }
      a.explicit-link:hover {
        color: var(--blue-60);
        background-color: var(--blue-10);
      }
      a.explicit-link:focus {
        color: var(--blue-60);
        border: 1px solid var(--blue-50);
      }
      a[href]:hover {
        text-decoration: underline;
      }
      .separator {
        color: var(--crumb-separator-color, var(--gray-60));
        display: inline-block;
        vertical-align: middle;
      }
    `];
  }

  render() {
    return html`
      <div class="crumb-container">
        ${this.items.map(this._renderItem, this)}
        <span class="crumb">
          <slot @slotchange="${this._onSlotChange}"></slot>
        </span>
      </div>
    `;
  }

  _renderItem(item, index) {
    if (!item) {
      return html``;
    }
    const isLast = index === this.items.length - 1;
    const crumb = (item.href || item.onClick) ? this._renderAsLink(item, isLast) : this._renderAsText(item, isLast);
    const separator = (isLast && !this.renderFinalSeparator)
      ? html``
      : html`<span class="separator">&nbsp;>&nbsp;</span>`;

    return html`${crumb}${separator}`;
  }

  _renderAsLink(item, isLast) {
    return html`
      <a
        href="${item.href || 'javascript:void(0)'}"
        class="${this._itemClass(isLast)}"
        title="${(item.name)}"
        @click="${() => item.onClick && item.onClick()}"
      >
        ${(item.name)}
      </a>`;
  }

  _renderAsText(item, isLast) {
    return html`<span class="${this._itemClass(isLast)}" title="${(item.name)}">${(item.name)}</span>`;
  }

  _itemClass(isLast) {
    return [
      'crumb',
      ...(isLast ? ['last'] : []),
      ...(this.explicitlinks ? ['explicit-link'] : []),
    ].join(' ');
  }

  _onSlotChange(e) {
    this.renderFinalSeparator = e.target.assignedNodes().length > 1;
  }
}

window.customElements.define('bn-bread-crumbs', BnBreadCrumbs);
