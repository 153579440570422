import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import classnames from 'classnames';
import { makeStyles, Alert, AlertTitle } from '~/lib/bn-material-ui';

export function BnErrorBoundary({
  children,
  locationTag,
  fallbackComponent,
  fallbackTitle = 'Error',
  fallbackMessage = 'Our system has encountered an error. Development has been notified and they are working to resolve it. We apologize for the inconvenience.',
  fallbackClassName = '',
}) {
  return (
    <ErrorBoundary
      fallback={fallbackComponent || <Fallback fallbackTitle={fallbackTitle} fallbackMessage={fallbackMessage} fallbackClassName={fallbackClassName} />}
      beforeCapture={(scope) => {
        scope.setTag('BnErrorBoundary', true);
        if (locationTag) {
          scope.setTag('location', locationTag);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

function Fallback({ fallbackTitle, fallbackMessage, fallbackClassName }) {
  return (
    <ThemeLessErrorDisplay
      className={fallbackClassName}
      title={fallbackTitle}
      content={fallbackMessage}
    />
  );
}

// Not depending on theme being loaded here
// We put the error boundary outside the theme provider (as there could be errors in the theme itself)
const useStyles = makeStyles(() => {
  return {
    alert: {
      backgroundColor: '#BB342A',
      color: 'white',
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .MuiAlertTitle-root': {
        color: 'white',
      },
    },
  };
});

export function ThemeLessErrorDisplay ({ className, title, content }) {
  const styles = useStyles();

  return (
    <Alert className={classnames(styles.alert, className)} severity='error'>
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  );
}
