// inspired by PWA Helpers router

export const installRouter = (locationChangeConfirmationHandler, onLocationChangeHandler) => {
  const shouldChange = (e) => locationChangeConfirmationHandler ? locationChangeConfirmationHandler(e) : true;
  const unloadCallback = e => shouldChange(e) || e.preventDefault();
  window.addEventListener('beforeunload', unloadCallback);

  const onClick = (e) => {
    if (
      e.defaultPrevented ||
      e.button !== 0 ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey
    ) {
      return;
    }

    const anchor = e.composedPath().filter((n) => n.tagName === 'A')[0];
    if (
      !anchor ||
      anchor.target ||
      anchor.hasAttribute('download') ||
      anchor.getAttribute('rel') === 'external'
    ) {
      return;
    }

    const { href } = anchor;
    if (!href || href.indexOf('mailto:') !== -1) {
      return;
    }

    const { location } = window;
    const origin = location.origin || `${location.protocol}//${location.host}`;
    if (href.indexOf(origin) !== 0) {
      return;
    }

    e.preventDefault();
    if (href !== location.href) {
      if (locationChangeConfirmationHandler && !locationChangeConfirmationHandler(e)) {
        return;
      }
      window.history.pushState({}, '', href);
      onLocationChangeHandler(location, e);
    }
  };
  document.body.addEventListener('click', onClick);

  const popstateCallBack = (e) => {
    if (locationChangeConfirmationHandler && !locationChangeConfirmationHandler(e)) {
      return;
    }
    onLocationChangeHandler(window.location, e);
  };
  window.addEventListener('popstate', popstateCallBack);
  onLocationChangeHandler(window.location, null);

  return () => {
    document.body.removeEventListener('click', onClick);
    window.removeEventListener('popstate', popstateCallBack);
    window.removeEventListener('beforeunload', unloadCallback);
  };
};
