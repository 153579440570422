import React, { useEffect } from 'react';
import { entityStore } from './entity-store.js';
import useStoreValues from './use-store-values.js';
import './styles/root-styles.jsx';

import { useUserNavigationWatcher } from './root-utility-helpers/use-user-navigation-watcher.js';
import { useAuthenticationListeners } from './root-utility-helpers/use-authentication-listeners.js';
import { useRootEntityEffects } from './root-utility-helpers/use-root-entity-effects.js';
import { useCurrentUserInitializer } from './root-utility-helpers/use-current-user-initializer.js';
import { useSentry } from './root-utility-helpers/use-sentry.js';
import { usePostHog } from './root-utility-helpers/use-posthog.js';
import { useCurrentOrganizationWatcher } from './root-utility-helpers/current-organization-watcher.js';
import { useLocalize } from './root-utility-helpers/use-localize.js';

export default function RootUtility (config) {
  useRootEntityEffects({ apiRootHref: config['api-root'] });
  useSentry(config);
  usePostHog(config);
  useLocalize(config);
  useUserNavigationWatcher();
  useAuthenticationListeners(config);
  useStorePathChangedListener();
  useCurrentUserInitializer();
  useCurrentOrganizationWatcher();
  return <root-styles config={JSON.stringify(config)} />;
}

/**
 * Listens to changes to store.path and updates misc things
 */
function useStorePathChangedListener() {
  const { path } = useStoreValues(['path']);

  useEffect(() => {
    // Update the title to match the current path
    document.title = `BinSentry${path ? path.replace(/\//g, ' ') : ''}`;

    // Clear any expired items from the entity store whenever we change paths (ie navigate)
    entityStore.clearExpiredItems();
  }, [path]);
}
