import { SirenActionMixin } from '../utility/siren-action-mixin';
import { LitEntity, css, html } from '../utility/lit-entity';
import { sharedStyles } from '../styles/shared-styles';
import './search-modal';

class SearchBox extends SirenActionMixin(LitEntity) {
  constructor() {
    super();
    this.hideModal = true;
    this.searchBinsHref = '';
    this.searchOrgsHref = '';
    this.searchDevicesHref = '';
    this.devicesHref = '';
  }

  static get properties() {
    return {
      hideModal: { type: Boolean, attribute: false },
      searchBinsHref: { type: String, attribute: false },
      searchOrgsHref: { type: String, attribute: false },
      searchDevicesHref: { type: String, attribute: false },
      searchQuery: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: block;
          padding: 10px 14px;
        }
        .search-box-button {
          background-color: var(--white-base);
          border: 1px solid var(--gray-60);
          border-radius: 1em;
          box-sizing: border-box;
          color: var(--black-base);
          display: inline-block;
          font-family: var(--content-font);
          font-size: 1em;
          line-height: 1.4em;
          padding: 3px 6px;
          text-align: left;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .search-box-button:hover {
          cursor: pointer;
          color: var(--black-base);
          font-weight: bold;
        }
        bn-icon {
          margin-right: 0.25em;
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('keydown', this._keydown.bind(this));
    document.addEventListener(
      'search-geo-filter',
      this._searchGeoFilter.bind(this),
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('keydown', this._keydown);
    document.removeEventListener('search-geo-filter', this._searchGeoFilter);
  }

  _searchGeoFilter(e) {
    const modal = this.shadowRoot.querySelector('search-modal');
    if (modal) {
      modal.setGeoFilter(e);
      this.hideModal = false;
    }
  }

  _keydown(e) {
    const input = e
      .composedPath()
      .filter((n) => ['INPUT', 'TEXTAREA'].includes(n.tagName))[0];
    if (input) {
      return;
    }
    if (e.key === '/') {
      e.preventDefault();
      this.open();
    }
  }

  open() {
    this.hideModal = false;
  }

  close() {
    this.hideModal = true;
  }

  render() {
    return html`
      <button
        class="search-box-button"
        @click="${() => (this.hideModal = false)}"
      >
        <bn-icon name="far fa-search"></bn-icon> ${this.searchQuery || 'Search'}
      </button>
      <search-modal
        ?hide="${this.hideModal}"
        .searchBinsHref="${this.searchBinsHref}"
        .searchOrgsHref="${this.searchOrgsHref}"
        .searchDevicesHref="${this.searchDevicesHref}"
        @close="${() => (this.hideModal = true)}"
        @query="${this._onModalQuery}"
      >
      </search-modal>
    `;
  }

  async _entityChanged(entity) {
    super._entityChanged(entity);
    this.searchBinsHref = this.getHref(
      'https://api.binsentry.com/rel/search-bins',
    );
    this.searchOrgsHref = this.getHref(
      'https://api.binsentry.com/rel/search-organizations',
    );

    const devicesHref = this.getHref('https://api.binsentry.com/rel/devices');
    if (this.devicesHref !== devicesHref) {
      this.devicesHref = devicesHref;
      if (!this.devicesHref) {
        this.searchDevicesHref = undefined;
        return;
      }
      const devicesEntity = await this.fetchSiren(this.devicesHref);
      this.searchDevicesHref = (
        devicesEntity.getActionByName('find-device') || {}
      ).href;
    }
  }

  _onModalQuery(e) {
    const { query } = e.detail;
    this.searchQuery = query.trim();
  }
}

window.customElements.define('search-box', SearchBox);
